import { CircularProgress, Stack, Typography } from '@mui/material';
import React, { Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useDevice } from 'src/hooks/useDevice';
const Home = React.lazy(() => import('@modules/Home/Home'));
const Faq = React.lazy(() => import('@modules/Faq/Faq'));
export default function Router() {
    const { isMobile } = useDevice();
    return (React.createElement(Suspense, { fallback: React.createElement(Stack, { spacing: 2, justifyContent: 'center', alignItems: 'center' },
            React.createElement(CircularProgress, { size: 32, color: 'primary' }),
            React.createElement(Typography, { variant: 'body1', color: 'primary' }, "Loading")) },
        React.createElement(Routes, null,
            React.createElement(Route, { path: '/', element: React.createElement(Home, null) }),
            React.createElement(Route, { path: '/', element: React.createElement(Stack, { direction: 'row', justifyContent: 'center' },
                    React.createElement(Stack, { justifyContent: 'space-around', direction: 'column', width: theme => theme.breakpoints.values.xl },
                        React.createElement(Stack, { py: 5, px: isMobile ? 3 : 10, spacing: 4 },
                            React.createElement(Outlet, null)))) },
                React.createElement(Route, { path: '/faq', element: React.createElement(Faq, null) })),
            React.createElement(Route, { path: '*', element: React.createElement(Navigate, { to: '/' }) }))));
}
