import { SvgIcon } from '@mui/material';
import React from 'react';
export function CloseIcon({ disabled, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: props.width ?? 16, height: props.height ?? 16, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '16', height: '16', viewBox: '0 0 16 16', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("path", { d: 'M15.9688 14.0199L9.84608 7.95657L15.9074 1.84057L14.0194 -0.0307617L7.95942 6.08857L1.84208 0.029905L-0.03125 1.90324L6.09275 7.97324L0.0294167 14.0959L1.90275 15.9692L7.97742 9.84124L14.0974 15.9079L15.9688 14.0199Z', fill: disabled ? '#A3A3A3' : 'white' }))));
}
export function CopyIcon({ disabled, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 14, height: 14, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '14', height: '14', viewBox: '0 0 14 14', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("g", { clipPath: 'url(#clip0_702_9799)' },
                React.createElement("path", { d: 'M12.8333 3.5V12.8333H3.5V3.5H12.8333ZM14 2.33333H2.33333V14H14V2.33333ZM0 12.25V0H12.25V1.16667H1.16667V12.25H0Z', fill: 'white' })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: 'clip0_702_9799' },
                    React.createElement("rect", { width: '14', height: '14', fill: disabled ? '#A3A3A3' : 'white' }))))));
}
export function CheckIcon({ disabled, dark, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 14, height: 14, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '14', height: '14', viewBox: '0 0 14 14', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("path", { d: 'M5.38462 11.936L0 6.22936L1.50662 4.6899L5.35608 8.71383L12.4336 1.16675L14 2.67767L5.38462 11.936Z', fill: dark ? '#191919' : '#4AE97A' }))));
}
export function WarningIcon({ disabled, dark, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 14, height: 14, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '16', height: '16', viewBox: '0 0 16 16', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("path", { d: 'M1.39732 13.2572L7.56265 2.25722C7.65132 2.09922 7.81865 2.00122 8.00065 2.00122C8.18198 2.00122 8.34998 2.09922 8.43798 2.25722L14.6033 13.2572C14.646 13.3339 14.6673 13.4179 14.6673 13.5019C14.6673 13.7586 14.4613 14.0012 14.166 14.0012H1.83532C1.54198 14.0012 1.33398 13.7612 1.33398 13.5019C1.33398 13.4179 1.35465 13.3339 1.39732 13.2572ZM8.00198 8.66989C7.72598 8.66989 7.50198 8.89389 7.50198 9.16989V11.5032C7.50198 11.7792 7.72598 12.0032 8.00198 12.0032C8.27798 12.0032 8.50198 11.7792 8.50198 11.5032V9.16989C8.50198 8.89389 8.27798 8.66989 8.00198 8.66989ZM8.00065 6.66989C7.63265 6.66989 7.33398 6.96855 7.33398 7.33655C7.33398 7.70455 7.63265 8.00322 8.00065 8.00322C8.36865 8.00322 8.66732 7.70455 8.66732 7.33655C8.66732 6.96855 8.36865 6.66989 8.00065 6.66989Z', fill: dark ? '#191919' : '#4AE97A' }))));
}
export function WarningIconPrimary({ disabled, dark, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 24, height: 24, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("path", { d: 'M2.095 19.886L11.343 3.38595C11.476 3.14895 11.727 3.00195 12 3.00195C12.272 3.00195 12.524 3.14895 12.656 3.38595L21.904 19.886C21.968 20.001 22 20.127 22 20.253C22 20.638 21.691 21.002 21.248 21.002H2.752C2.312 21.002 2 20.642 2 20.253C2 20.127 2.031 20.001 2.095 19.886ZM12.002 13.005C11.588 13.005 11.252 13.341 11.252 13.755V17.255C11.252 17.669 11.588 18.005 12.002 18.005C12.416 18.005 12.752 17.669 12.752 17.255V13.755C12.752 13.341 12.416 13.005 12.002 13.005ZM12 10.005C11.448 10.005 11 10.453 11 11.005C11 11.557 11.448 12.005 12 12.005C12.552 12.005 13 11.557 13 11.005C13 10.453 12.552 10.005 12 10.005Z', fill: '#FD4B1E' }))));
}
export function RefreshIcon({ disabled, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 24, height: 25, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '24', height: '25', viewBox: '0 0 24 25', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("path", { d: 'M21.897 13.9039L21.905 13.8469V13.8489C21.929 13.6709 21.949 13.4919 21.963 13.3119C21.987 13.0099 21.774 12.5009 21.214 12.5009C20.823 12.5009 20.499 12.8009 20.467 13.1909C20.449 13.4119 20.423 13.6309 20.389 13.8469C19.744 17.8979 16.231 20.9999 11.998 20.9999C8.961 20.9999 6.294 19.4029 4.792 17.0049L6.783 16.9999C7.197 16.9999 7.533 16.6639 7.533 16.2499C7.533 15.8359 7.197 15.4999 6.783 15.4999H2.75C2.336 15.4999 2 15.8359 2 16.2499V20.2989C2 20.7129 2.336 21.0489 2.75 21.0489C3.164 21.0489 3.5 20.7139 3.5 20.2989L3.503 17.7739C5.268 20.6099 8.414 22.4999 11.998 22.4999C17.04 22.4999 21.215 18.7589 21.897 13.9039ZM2.123 10.9299L2.114 10.9859V10.9839C2.079 11.2169 2.051 11.4529 2.032 11.6919C2.008 11.9939 2.221 12.5029 2.781 12.5029C3.172 12.5029 3.496 12.2029 3.528 11.8129C3.55 11.5329 3.586 11.2569 3.635 10.9859C4.351 7.01791 7.824 4.00391 11.997 4.00391C15.034 4.00391 17.701 5.60091 19.203 7.99891L17.212 8.00391C16.798 8.00391 16.462 8.33991 16.462 8.75391C16.462 9.16791 16.798 9.50391 17.212 9.50391H21.245C21.659 9.50391 21.995 9.16791 21.995 8.75391V4.70491C21.995 4.29091 21.659 3.95491 21.245 3.95491C20.831 3.95491 20.495 4.28991 20.495 4.70491L20.492 7.22991C18.727 4.39391 15.581 2.50391 11.997 2.50391C7.013 2.50391 2.877 6.15791 2.123 10.9299Z', fill: disabled ? '#A3A3A3' : 'white' }))));
}
export function ListViewIcon({ disabled, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 24, height: 25, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '24', height: '25', viewBox: '0 0 24 25', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("path", { d: 'M3.3 15.9C4.017 15.9 4.6 16.483 4.6 17.2C4.6 17.917 4.017 18.5 3.3 18.5C2.583 18.5 2 17.917 2 17.2C2 16.483 2.583 15.9 3.3 15.9ZM6 17.75C6 17.336 6.336 17 6.75 17H21.25C21.664 17 22 17.336 22 17.75C22 18.164 21.664 18.5 21.25 18.5H6.75C6.336 18.5 6 18.164 6 17.75ZM3.3 11.2C4.017 11.2 4.6 11.783 4.6 12.5C4.6 13.217 4.017 13.8 3.3 13.8C2.583 13.8 2 13.217 2 12.5C2 11.783 2.583 11.2 3.3 11.2ZM6 12.5C6 12.086 6.336 11.75 6.75 11.75H21.25C21.664 11.75 22 12.086 22 12.5C22 12.914 21.664 13.25 21.25 13.25H6.75C6.336 13.25 6 12.914 6 12.5ZM3.3 6.5C4.017 6.5 4.6 7.083 4.6 7.8C4.6 8.517 4.017 9.1 3.3 9.1C2.583 9.1 2 8.517 2 7.8C2 7.083 2.583 6.5 3.3 6.5ZM6 7.25C6 6.836 6.336 6.5 6.75 6.5H21.25C21.664 6.5 22 6.836 22 7.25C22 7.664 21.664 8 21.25 8H6.75C6.336 8 6 7.664 6 7.25Z', fill: disabled ? '#A3A3A3' : 'white' }))));
}
export function GridViewIcon({ disabled, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 24, height: 25, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '24', height: '25', viewBox: '0 0 24 25', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("path", { d: 'M21 4.5C21 4.022 20.621 3.5 20 3.5H4C3.38 3.5 3 4.019 3 4.5V20.5C3 21.121 3.52 21.5 4 21.5H20C20.478 21.5 21 21.121 21 20.5V4.5ZM8.5 20H4.5V16H8.5V20ZM10 16H14V20H10V16ZM19.5 16V20H15.5V16H19.5ZM4.5 10.5H8.5V14.5H4.5V10.5ZM10 10.5H14V14.5H10V10.5ZM15.5 10.5H19.5V14.5H15.5V10.5ZM4.5 5H8.5V9H4.5V5ZM10 5H14V9H10V5ZM15.5 5H19.5V9H15.5V5Z', fill: disabled ? '#A3A3A3' : 'white' }))));
}
export function PlusIcon({ disabled, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 17, height: 17, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '17', height: '17', viewBox: '0 0 17 17', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("g", { clipPath: 'url(#clip0_824_8399)' },
                React.createElement("path", { d: 'M16.5 7.16667H9.83333V0.5H7.16667V7.16667H0.5V9.83333H7.16667V16.5H9.83333V9.83333H16.5V7.16667Z', fill: disabled ? '#A3A3A3' : 'white' })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: 'clip0_824_8399' },
                    React.createElement("rect", { width: '16', height: '16', fill: disabled ? '#A3A3A3' : 'white', transform: 'translate(0.5 0.5)' }))))));
}
export function BinIcon({ disabled, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 17, height: 17, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '17', height: '17', viewBox: '0 0 17 17', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("g", { clipPath: 'url(#clip0_824_8775)' },
                React.createElement("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M13.1654 16.5H3.83203C3.09603 16.5 2.4987 15.9027 2.4987 15.1667V3.83333H1.83203V2.5H5.83203V1.5C5.83203 0.948667 6.2807 0.5 6.83203 0.5H10.1654C10.7154 0.5 11.1654 0.947333 11.1654 1.5V2.5H15.1654V3.83333H14.4987V15.1667C14.4987 15.9027 13.9014 16.5 13.1654 16.5ZM13.1654 3.83333H3.83203V14.8333C3.83203 15.0173 3.98136 15.1667 4.16536 15.1667H12.832C13.016 15.1667 13.1654 15.0173 13.1654 14.8333V3.83333ZM7.16536 6.5C7.16536 6.132 6.8667 5.83333 6.4987 5.83333C6.1307 5.83333 5.83203 6.132 5.83203 6.5V12.5C5.83203 12.868 6.1307 13.1667 6.4987 13.1667C6.8667 13.1667 7.16536 12.868 7.16536 12.5V6.5ZM11.1654 6.5C11.1654 6.132 10.8667 5.83333 10.4987 5.83333C10.1307 5.83333 9.83203 6.132 9.83203 6.5V12.5C9.83203 12.868 10.1307 13.1667 10.4987 13.1667C10.8667 13.1667 11.1654 12.868 11.1654 12.5V6.5ZM9.83203 1.83333H7.16536V2.5H9.83203V1.83333Z', fill: disabled ? '#A3A3A3' : 'white' })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: 'clip0_824_8775' },
                    React.createElement("rect", { width: '16', height: '16', fill: disabled ? '#A3A3A3' : 'white', transform: 'translate(0.5 0.5)' }))))));
}
export function MenuIcon({ disabled, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 24, height: 24, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M12 16.4951C13.242 16.4951 14.25 17.5031 14.25 18.7451C14.25 19.9871 13.242 20.9951 12 20.9951C10.758 20.9951 9.75 19.9871 9.75 18.7451C9.75 17.5031 10.758 16.4951 12 16.4951ZM12 9.74512C13.242 9.74512 14.25 10.7531 14.25 11.9951C14.25 13.2371 13.242 14.2451 12 14.2451C10.758 14.2451 9.75 13.2371 9.75 11.9951C9.75 10.7531 10.758 9.74512 12 9.74512ZM12 2.99512C13.242 2.99512 14.25 4.00312 14.25 5.24512C14.25 6.48712 13.242 7.49512 12 7.49512C10.758 7.49512 9.75 6.48712 9.75 5.24512C9.75 4.00312 10.758 2.99512 12 2.99512Z', fill: disabled ? '#A3A3A3' : 'white' }))));
}
export function ExpandIcon({ disabled, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 24, height: 24, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("path", { d: 'M0 7.33L2.829 4.5L12.004 13.839L21.171 4.5L24 7.33L12.004 19.5L0 7.33Z', fill: 'black' }))));
}
export function MinimizeIcon({ disabled, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 24, height: 24, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("path", { d: 'M0 16.67L2.829 19.5L12.004 10.161L21.171 19.5L24 16.67L12.004 4.5L0 16.67Z', fill: 'black' }))));
}
export function ChevronUpIcon({ disabled, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 16, height: 17, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '16', height: '17', viewBox: '0 0 16 17', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("path", { d: 'M0 11.6133L1.886 13.5L8.00267 7.274L14.114 13.5L16 11.6133L8.00267 3.5L0 11.6133Z', fill: 'white' }))));
}
export function DisabledPhoneIcon({ disabled, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 16, height: 17, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '16', height: '17', viewBox: '0 0 16 17', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("g", { clipPath: 'url(#clip0_981_5326)' },
                React.createElement("path", { d: 'M11.4428 13.404H4.80495L3.17188 14.9045V15.6052C3.17188 16.0103 3.5004 16.3389 3.90564 16.3389H12.0946C12.4998 16.3389 12.8284 16.0104 12.8284 15.6052V6.03174L11.4429 7.3048L11.4428 13.404ZM8.0001 15.5883C7.58278 15.5883 7.24438 15.2499 7.24438 14.8326C7.24438 14.4152 7.58278 14.0768 8.0001 14.0768C8.41739 14.0768 8.75583 14.4152 8.75583 14.8326C8.75583 15.2499 8.41739 15.5883 8.0001 15.5883Z', fill: '#FD4B1E' }),
                React.createElement("path", { d: 'M15.3349 0.843606C15.0211 0.502103 14.4899 0.479642 14.1484 0.793435L12.8283 2.00645V1.07263C12.8283 0.667397 12.4998 0.338867 12.0945 0.338867H3.90558C3.50039 0.338867 3.17182 0.667355 3.17182 1.07263V10.8792L0.715424 13.1363C0.373921 13.4502 0.351459 13.9813 0.665252 14.3228C0.83084 14.503 1.05693 14.5944 1.28377 14.5944C1.48677 14.5944 1.69039 14.5212 1.85165 14.373L15.2847 2.03009C15.6262 1.71626 15.6486 1.18507 15.3349 0.843606ZM4.55731 1.72436H11.4428V3.27951L4.55731 9.60613V1.72436Z', fill: '#FD4B1E' })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: 'clip0_981_5326' },
                    React.createElement("rect", { width: '16', height: '16', fill: 'white', transform: 'translate(0 0.338867)' }))))));
}
export function ArrowDownIcon({ disabled, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 24, height: 24, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("path", { d: 'M5.21281 14.523C5.21281 14.523 9.71781 19.024 11.4728 20.778C11.6188 20.924 11.8108 20.997 12.0028 20.997C12.1948 20.997 12.3858 20.924 12.5328 20.778C14.2868 19.025 18.7908 14.523 18.7908 14.523C18.9358 14.379 19.0078 14.189 19.0078 13.999C19.0078 13.806 18.9338 13.614 18.7868 13.467C18.4948 13.174 18.0208 13.172 17.7308 13.463L12.7528 18.441L12.7528 3.74902C12.7528 3.33502 12.4168 2.99902 12.0028 2.99902C11.5888 2.99902 11.2528 3.33502 11.2528 3.74902L11.2528 18.441L6.27381 13.462C5.98481 13.173 5.51181 13.176 5.21981 13.468C5.07181 13.616 4.99781 13.809 4.99781 14.001C4.99781 14.191 5.06981 14.379 5.21281 14.523Z', fill: 'white' }))));
}
export function ArrowUpIcon({ disabled, ...props }) {
    return (React.createElement(SvgIcon, { ...props, sx: { width: 24, height: 24, justifyContent: 'center', display: 'flex' } },
        React.createElement("svg", { "clip-rule": 'evenodd', "fill-rule": 'evenodd', "stroke-linejoin": 'round', "stroke-miterlimit": '2', viewBox: '0 0 24 24', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("path", { d: 'm18.787 9.473s-4.505-4.502-6.259-6.255c-.147-.146-.339-.22-.53-.22-.192 0-.384.074-.531.22-1.753 1.753-6.256 6.252-6.256 6.252-.147.147-.219.339-.217.532.001.19.075.38.221.525.292.293.766.295 1.056.004l4.977-4.976v14.692c0 .414.336.75.75.75.413 0 .75-.336.75-.75v-14.692l4.978 4.978c.289.29.762.287 1.055-.006.145-.145.219-.335.221-.525.002-.192-.07-.384-.215-.529z', fill: 'white' }))));
}
