import { AppBar, Box, Divider, Button, Stack, IconButton, SwipeableDrawer, useScrollTrigger, CssBaseline } from '@mui/material';
import React, { useState } from 'react';
import logo from '@assets/images/logo_primary.svg';
import { useNavigate } from 'react-router-dom';
import { useDevice } from 'src/hooks/useDevice';
import { CloseIcon, MenuIcon } from '@assets/icons/Icons';
function ElevationScroll(props) {
    const { children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });
    return React.cloneElement(children, {
        // elevation: trigger ? 4 : 0,
        style: {
            color: trigger ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)',
            backgroundColor: trigger ? 'rgba(253, 75, 30, 1)' : 'rgba(253, 75, 30, 0.0)',
            transition: trigger ? 'background-color 400ms ease-in-out' : 'none',
            backdropFilter: 'blur(8px)',
            WebkitBackdropFilter: 'blur(8px)'
        }
    });
}
const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
export default function Topbar() {
    const { isMobile } = useDevice();
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const menuItems = [
        {
            name: 'Why upayed',
            path: '#why-upayed'
        },
        {
            name: 'Getting Started',
            path: '#getting-started'
        },
        {
            name: 'FAQs',
            path: '#faq'
        }
    ];
    function renderLogo() {
        return (React.createElement(Box, { onClick: () => {
                navigate('/');
                setMobileDrawerOpen(false);
            }, sx: { display: 'flex', alignItems: 'center', cursor: 'pointer' } },
            React.createElement("img", { height: 28, src: logo })));
    }
    function renderMenu() {
        return (React.createElement(Stack, { direction: isMobile ? 'column' : 'row' },
            menuItems
                .filter(m => !m.hidden)
                .map(item => (React.createElement(Stack, { key: item.name, px: 1.5 },
                React.createElement(Button, { sx: isMobile
                        ? {
                            justifyContent: 'flex-start',
                            height: 24 * 3,
                            color: theme => (location.pathname.includes(item.path) ? theme.palette.primary.main : theme.palette.text.primary)
                        }
                        : {
                            color: theme => (location.pathname.includes(item.path) ? theme.palette.primary.main : theme.palette.primary.contrastText),
                            fontWeight: 500
                        }, onClick: () => {
                        window.document.querySelector(item.path)?.scrollIntoView({ behavior: 'smooth' });
                        setMobileDrawerOpen(false);
                    } }, item.name),
                isMobile && React.createElement(Divider, null)))),
            React.createElement(Stack, { px: 1.5 }, isMobile && React.createElement(Divider, null))));
    }
    function renderMobileDrawer() {
        return (React.createElement(SwipeableDrawer, { PaperProps: { sx: { minWidth: '100%' } }, disableBackdropTransition: !iOS, disableDiscovery: iOS, anchor: 'right', open: mobileDrawerOpen, onClose: () => setMobileDrawerOpen(false), onOpen: () => setMobileDrawerOpen(true) },
            React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', sx: { py: 4, px: isMobile ? 3 : 10 } },
                renderLogo(),
                React.createElement(IconButton, { onClick: () => setMobileDrawerOpen(false) },
                    React.createElement(CloseIcon, { width: 24, height: 24 }))),
            renderMenu()));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(CssBaseline, null),
        React.createElement(ElevationScroll, null,
            React.createElement(AppBar, { elevation: 0, sx: { bgcolor: theme => theme.palette.primary.main } },
                React.createElement(Stack, { direction: 'row', justifyContent: 'center' },
                    React.createElement(Stack, { direction: 'row', flexGrow: 1, alignItems: 'center', justifyContent: 'space-between', sx: { py: 4, px: isMobile ? 3 : 5, boxSizing: 'border-box' }, maxWidth: theme => theme.breakpoints.values.xl },
                        renderLogo(),
                        isMobile ? (React.createElement(IconButton, { onClick: () => setMobileDrawerOpen(true) },
                            React.createElement(MenuIcon, null))) : (renderMenu())),
                    renderMobileDrawer())))));
}
