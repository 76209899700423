import { Slide } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import React from 'react';
const Transition = React.forwardRef(function Transition(props, ref) {
    return React.createElement(Slide, { direction: 'up', ref: ref, ...props });
});
export const theme = createTheme({
    palette: {
        primary: {
            main: '#FD4B1E',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#000000',
            contrastText: '#ffffff'
        },
        background: {
            default: '#ffffff',
            paper: '#ffffff'
        },
        divider: '#E8E8E8',
        text: {
            primary: '#000000',
            secondary: '#ffffff'
        },
        success: {
            main: '#4AE97A'
        },
        warning: {
            main: '#FDBF1E'
        },
        error: {
            main: '#FD4B1E'
        }
    },
    shape: {
        borderRadius: 8
    },
    typography: {
        allVariants: {
            fontFamily: 'Raleway, sans-serif',
            fontWeight: 700
        },
        hero1: {
            fontSize: 82,
            lineHeight: '94px'
        },
        hero2: {
            fontSize: 68,
            lineHeight: '76px',
            letterSpacing: '-1.2px'
        },
        h1: {
            fontSize: 60,
            lineHeight: '68px',
            letterSpacing: '-1.2px'
        },
        h2: {
            fontSize: 50,
            lineHeight: '60px',
            letterSpacing: '-1px'
        },
        h3: {
            fontSize: 44,
            lineHeight: '55px',
            letterSpacing: '-0.88px'
        },
        h4: {
            fontSize: 34,
            lineHeight: '40px',
            letterSpacing: '-0.68px'
        },
        h5: {
            fontSize: 28,
            lineHeight: '32px'
        },
        h6: {
            fontSize: 24,
            lineHeight: '28px',
            letterSpacing: '-0.48px'
        },
        subtitleL: {
            fontWeight: 400,
            fontSize: 28,
            lineHeight: '41px'
        },
        subtitleM: {
            fontWeight: 400,
            fontSize: 24,
            lineHeight: '36px'
        },
        subtitleS: {
            fontWeight: 400,
            fontSize: 20,
            lineHeight: '28px'
        },
        bodyL: {
            fontFamily: 'Inter, sans-serif',
            fontWeight: 400,
            fontSize: 18,
            lineHeight: '26px'
        },
        bodyM: {
            fontFamily: 'Inter, sans-serif',
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '24px'
        },
        bodyS: {
            fontFamily: 'Inter, sans-serif',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '22px'
        },
        bodyXS: {
            fontFamily: 'Inter, sans-serif',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '18px'
        },
        bodyXXS: {
            fontFamily: 'Inter, sans-serif',
            fontWeight: 400,
            fontSize: 12,
            lineHeight: '16px'
        },
        contentL: {
            fontStyle: 'italic',
            fontWeight: 100,
            fontSize: 24,
            lineHeight: '30px',
            letterSpacing: '-0.48px'
        },
        contentM: {
            fontStyle: 'italic',
            fontWeight: 100,
            fontSize: 20,
            lineHeight: '26px',
            letterSpacing: '-0.4px'
        },
        contentS: {
            fontStyle: 'italic',
            fontWeight: 100,
            fontSize: 18,
            lineHeight: '24px',
            letterSpacing: '-0.36px'
        }
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '& span': {
                        color: theme.palette.primary.main
                    },
                    '& u': {
                        textDecoration: 'none',
                        borderBottom: `8px solid ${theme.palette.primary.main}`
                    }
                })
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: ({ theme }) => ({
                    background: 'rgba(25, 25, 25, 0.50)',
                    backdropFilter: 'blur(8px)'
                })
            }
        },
        MuiDialog: {
            defaultProps: {
                TransitionComponent: Transition,
                fullWidth: true
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    '& .MuiPaper-root': {
                        background: `rgba(25, 25, 25, 0.8)`,
                        borderRadius: '8px',
                        boxShadow: theme.shadows[24],
                        border: `1px solid ${theme.palette.divider}`,
                        paddingTop: 2
                    }
                })
            }
        },
        MuiDivider: {},
        MuiList: {},
        MuiMenuItem: {},
        MuiButton: {
            variants: [
                {
                    props: { variant: 'secondary_dark' },
                    style: ({ theme }) => ({
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.primary.contrastText
                    })
                }
            ],
            styleOverrides: {
                root: ({ theme }) => ({
                    fontSize: 16,
                    height: 42,
                    fontWeight: 500,
                    color: theme.palette.primary.contrastText,
                    textTransform: 'none',
                    '&.Mui-disabled': {
                        color: theme.palette.text.secondary,
                        backgroundColor: theme.palette.divider
                    }
                }),
                outlinedPrimary: ({ theme }) => ({
                    borderColor: theme.palette.primary.contrastText
                }),
                textPrimary: ({ theme }) => ({
                    ...theme.typography.bodyM,
                    fontWeight: 700,
                    color: theme.palette.primary.main
                }),
                containedPrimary: ({ theme }) => ({
                    ...theme.typography.bodyM,
                    fontWeight: 700
                }),
                outlinedSizeSmall: ({ theme }) => ({
                    fontSize: 14,
                    height: 32
                }),
                containedSizeSmall: ({ theme }) => ({
                    fontSize: 14,
                    height: 32
                }),
                containedSecondary: ({ theme }) => ({
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.primary.contrastText
                }),
                outlinedSecondary: ({ theme }) => ({
                    border: `2px solid ${theme.palette.secondary.main}`,
                    color: theme.palette.secondary.main,
                    '&:hover': {
                        border: `2px solid ${theme.palette.secondary.light}`,
                        color: theme.palette.secondary.light
                    }
                })
            }
        }
    }
});
